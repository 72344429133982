<template>
  <select class="custom-select" v-model="valueChild">
    <option></option>
    <option v-for="l in list" :key="l.id" :value="l.id">{{ l.name }} </option>
  </select>
</template>

<script>
import { GeneralService } from '../GeneralService.js';
export default {
  props: {
    value: {}
  },
  data: () => ({
    list: [],
  }),
  created() {
    GeneralService.getSpecialties({all: 1}).then(res => {
      this.list = res;
    });
  },
  computed: {
    valueChild: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); }
    }
  }
}
</script>

<style>

</style>
